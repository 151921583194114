<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__top">
        <div class="footer__logo">
          <img
            src="@/assets/img/icons/logo-footer.svg"
            alt=""
          />
        </div>
        <div class="footer__content">
          <div class="footer__content-item">
            <div
              v-if="$isMobile()"
              class="footer__dropdown-title"
              :class="{ 'is-dropdown': $isMobile(), active: isShowDropdown }"
              @click="toogleDropdown"
            >
              {{ $t("footer.categories") }}
              <img
                v-if="$isMobile()"
                src="@/assets/img/icons/dropdown-arrow-white.svg"
                alt=""
              />
            </div>
            <div class="footer__list">
              <ul
                v-if="isShowDropdown || !$isMobile()"
                class="footer__list-list footer__list-dropdown"
              >
                <li
                  class="footer__list-item"
                  v-for="(category, index) in categoriesFiltered"
                  :key="index"
                >
                  <locale-router-link
                    class="footer__list-link"
                    :to="`catalog?category=${category.id}`"
                  >
                    {{ category.label }}
                  </locale-router-link>
                </li>
              </ul>
            </div>
          </div>
          <ul class="footer__list">
            <li class="footer__list-item">
              <locale-router-link
                class="footer__list-link"
                to="stocks"
                >{{ $t("footer.stocks") }}</locale-router-link
              >
            </li>
            <li class="footer__list-item">
              <a
                class="footer__list-link"
                v-if="!$cookies.get('profile')"
                @click="getIsOpenHiddenModal(true)"
              >
                {{ $t("footer.heatingSystems") }}
              </a>
              <locale-router-link
                v-else
                class="footer__list-link"
                to="heating-systems/bat"
                >{{ $t("footer.heatingSystems") }}</locale-router-link
              >
            </li>
            <li class="footer__list-item">
              <locale-router-link
                class="footer__list-link"
                to="recipes"
                >{{ $t("footer.coctails") }}</locale-router-link
              >
            </li>
            <li class="footer__list-item">
              <locale-router-link
                class="footer__list-link"
                to="brands"
                >{{ $t("footer.partners") }}</locale-router-link
              >
            </li>
          </ul>
          <ul class="footer__list">
            <li class="footer__list-item">
              <locale-router-link
                class="footer__list-link"
                to="news"
                >{{ $t("footer.news") }}</locale-router-link
              >
            </li>
            <li class="footer__list-item">
              <locale-router-link
                to="useful-articles"
                class="footer__list-link"
                >{{ $t("footer.usefulArticles") }}</locale-router-link
              >
            </li>
            <li class="footer__list-item">
              <locale-router-link
                class="footer__list-link"
                to="about"
                >{{ $t("footer.about") }}</locale-router-link
              >
            </li>
            <li class="footer__list-item">
              <a
                class="footer__list-link"
                :href="ruleFile"
                target="_blank"
                >{{ $t("header.rules") }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container">
        <div class="footer__bottom-inner">
          <div class="footer__bottom-rights">
            2022©partyclub.kz <span>{{ $t("allRights") }}</span>
          </div>
          <div class="footer__bottom-content">
            <div class="footer__bottom-limitation">
              <img
                src="@/assets/img/icons/limitation21.svg"
                alt=""
              />
              <p
                class="footer__bottom-text"
                v-html="$t('age')"
              ></p>
            </div>

            <p
              class="footer__bottom-text"
              v-html="$t('order')"
            ></p>
          </div>
        </div>
      </div>
      <div class="bottom__nav">
        <div class="container">
          <bottom-navigation />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LocaleRouterLink from "../language/LocaleRouterLink.vue"
import { mapState, mapActions } from "vuex"
import { actionTypes } from "@/store"
import BottomNavigation from "./BottomNavigation.vue"

export default {
  name: "AppFooter",
  components: {
    LocaleRouterLink,
    BottomNavigation,
  },
  props: {
    ruleFile: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShowDropdown: true,
    }
  },
  computed: {
    ...mapState("categoriesModule", ["categoriesFiltered"]),
  },
  mounted() {
    if (this.$isMobile()) {
      this.isShowDropdown = false
    }
    // getAllCategories().then((res) => {
    //   this.categories = res
    // })
  },
  watch: {
    "$route.fullPath": {
      handler() {
        this.isShowDropdown = false
      },
    },
  },
  methods: {
    ...mapActions({
      getIsOpenHiddenModal: actionTypes.getIsOpenHiddenModal,
    }),
    toogleDropdown() {
      if (this.$isMobile()) {
        this.isShowDropdown = !this.isShowDropdown
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
